import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import "./card.css";
import LayersIcon from "@mui/icons-material/Layers";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import FilterHdrIcon from "@mui/icons-material/FilterHdr";
import Spinner from "../Spinner";
import { IoMdAdd, IoMdDownload, IoMdSave } from "react-icons/io";
import { useParams } from "react-router-dom";

const Card = ({
  getContour1,
  getContour2,
  clearContour,
  getMap1,
  getMap2,
  changeMap,
  currentMapTypeIndex,
  currentMapType,
  detectChange,
  loading,
  openModal,
  createdContours,
  visitId,
  renderContour,
  checkedRadio,
  setCheckedRadio,
  checkedRadioMap,
  setCheckedRadioMap,
  downloadContour,
}) => {
  // const contData = {
  //   1: {
  //     "9th Visit": {
  //       20: "1709626448_553.geojson",
  //     },
  //   },
  // };
  const { id, idx } = useParams();
  // useEffect(() => {
  //   if (!(createdContours || visitId)) return;

  //   console.log(visitId, createdContours[id][visitId]);
  // }, [createdContours, visitId]);
  return (
    <div
      style={{
        zIndex: 99,
        position: "absolute",
        top: "8%",
        left: "-10px",
        // width: isCollapsed ? "10px" : "19rem",
        height: "95vh",
        // opacity: '1'
        boxShadow: "1px 1px 7px 1px grey",
      }}
      id="caard"
      className={`card_container closed rounded`}
    >
      <div
        href="#"
        style={{
          // backgroundColor: "rgb(79 113 197)",
          backgroundColor: "#667FC1",
          width: "23vw",
          height: "95vh",

          // display: isCollapsed ? 'none' : 'block'
        }}
        className="block max-w-sm pl-6 pr-6 pt-2 bg-white border-gray-200 rounded shadow hover:bg-gray-100 overflow-scroll scrolling"
      >
        <div
          href="#"
          className="block text-center text-gray-900 rounded md:border-0 dark:text-black text-xl p-1.5 cursor-default"
        >
          Ortho Details
        </div>
        <div>
          <AccordionUsage
            downloadContour={downloadContour}
            renderContour={renderContour}
            getContour1={getContour1}
            getContour2={getContour2}
            clearContour={clearContour}
            getMap1={getMap1}
            getMap2={getMap2}
            id={id}
            createdContours={createdContours}
            visitId={visitId}
            changeMap={changeMap}
            currentMapTypeIndex={currentMapTypeIndex}
            currentMapType={currentMapType}
            detectChange={detectChange}
            openModal={openModal}
            checkedRadio={checkedRadio}
            setCheckedRadio={setCheckedRadio}
            checkedRadioMap={checkedRadioMap}
            setCheckedRadioMap={setCheckedRadioMap}
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: "1%",
            right: "2%",
          }}
        >
          {loading && <Spinner width="30px" />}
        </div>
      </div>
    </div>
  );
};

function AccordionUsage({
  getContour1,
  getContour2,
  clearContour,
  renderContour,
  changeMap,
  currentMapTypeIndex,
  detectChange,
  openModal,
  id,
  createdContours,
  visitId,
  checkedRadio,
  setCheckedRadio,
  checkedRadioMap,
  setCheckedRadioMap,
  downloadContour,
}) {
  useEffect(() => {
    console.log("changingggg....");
    setCheckedRadioMap(0);
    setCheckedRadio(0);
  }, [detectChange]);

  return (
    <div>
      <hr className="mb-0" />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{ marginLeft: "8%" }}
        >
          <FilterHdrIcon sx={{ color: "GrayText" }} /> &nbsp; Elevation Models
        </AccordionSummary>
        <AccordionDetails style={{ marginLeft: "13%" }}>
          <div className="d-flex gap-3" style={{ flexDirection: "column" }}>
            <div className="d-flex gap-2">
              <input
                type="radio"
                id="org"
                name="toggle-tile"
                checked={checkedRadioMap === 0}
                onClick={(e) => {
                  // getMap2()
                  changeMap(currentMapTypeIndex, "orthoPaths");
                  setCheckedRadioMap(0);
                  setCheckedRadio(0);
                }}
              />
              <label htmlFor="org"> Original</label>
            </div>
            <div className="d-flex gap-2">
              <input
                type="radio"
                id="dsm_1"
                checked={checkedRadioMap === 2}
                name="toggle-tile"
                onClick={(e) => {
                  changeMap(currentMapTypeIndex, "DSMPaths");
                  setCheckedRadioMap(2);
                  setCheckedRadio(0);
                }}
              />
              <label htmlFor="dsm_1"> DSM</label>
            </div>
            <div className="d-flex gap-2">
              <input
                type="radio"
                id="dtm_1"
                checked={checkedRadioMap === 1}
                name="toggle-tile"
                onClick={(e) => {
                  changeMap(currentMapTypeIndex, "DTMPaths");
                  setCheckedRadioMap(1);
                  setCheckedRadio(0);
                }}
              />
              <label htmlFor="dtm_1"> DTM</label>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{ marginLeft: "8%" }}
        >
          <LayersIcon sx={{ color: "GrayText" }} /> &nbsp; Contours
        </AccordionSummary>
        <AccordionDetails style={{ marginLeft: "13%" }}>
          <div className="d-flex gap-3" style={{ flexDirection: "column" }}>
            <div className="d-flex gap-2">
              <input
                type="radio"
                id="none"
                checked={checkedRadio === 0}
                name="toggle-contour"
                onClick={(e) => {
                  setCheckedRadio(0);
                  clearContour();
                }}
              />
              <label htmlFor="none"> None</label>
            </div>
            <div className="d-flex gap-2">
              <input
                type="radio"
                id="contour1"
                name="toggle-contour"
                checked={checkedRadio === 2}
                onClick={(e) => {
                  setCheckedRadio(2);
                  if (e.target.checked) {
                    getContour1();
                  } else {
                    clearContour();
                  }
                }}
              />
              <label htmlFor="contour1"> Contour 1</label>
            </div>

            <div className="d-flex gap-2">
              <input
                type="radio"
                id="contour2"
                name="toggle-contour"
                checked={checkedRadio === 1}
                onClick={(e) => {
                  setCheckedRadio(1);
                  if (e.target.checked) {
                    getContour2();
                  } else {
                    clearContour();
                  }
                }}
              />
              <label htmlFor="contour2"> Contour 2</label>
            </div>
            {createdContours &&
            createdContours[id] &&
            visitId &&
            createdContours[id][visitId]
              ? Object.keys(createdContours[id][visitId]).map((el, idx) => (
                  <div className="d-flex justify-content-between ">
                    <div className="d-flex gap-2 align-items-center">
                      <input
                        type="radio"
                        id={`cont_${idx + 3}`}
                        name="toggle-contour"
                        checked={checkedRadio === idx + 3}
                        onClick={(e) => {
                          setCheckedRadio(idx + 3);
                          if (e.target.checked) {
                            renderContour(createdContours[id][visitId][el]);
                          } else {
                            clearContour();
                          }
                        }}
                      />
                      <label htmlFor={`cont_${idx + 3}`}>Contour - {el}</label>
                    </div>

                    <div className="d-flex gap-2 justify-content-end">
                      <div>
                        <SaveBtn />
                      </div>
                      <div>
                        <button
                        title="Download Contour"
                          className="btn btn-sm px-2"
                          style={{ background: "#e65100", color: "white" }}
                          onClick={() => {
                            downloadContour(
                              createdContours[id][visitId][el].replace(
                                ".geojson",
                                ""
                              )
                            );
                          }}
                        >
                          <IoMdDownload size={21} />
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              : null}

            <hr style={{ padding: 0, margin: 0 }} />
            <button
              className="btn btn-light"
              onClick={() => openModal("add-contour")}
            >
              <div className="d-flex gap-2 mt-0">
                <IoMdAdd
                  size={20}
                  style={{ marginTop: "2px" }}
                  color="#e65100"
                />{" "}
                Add new Contour
              </div>
            </button>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const SaveBtn = () => {
  const [saved, setSaved] = useState(false);
  return (
    <button title="Save"
      className="btn bg-dark px-2 text-white btn-sm"
      style={{ display: `${saved ? "none" : "block"}` }}
      onClick={(e) => {
        setSaved(true);
      }}
    >
      <IoMdSave size={21} />
    </button>
  );
};

export default Card;
