import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import MultiSelect from "../MultiSelect";
import { Line } from "react-chartjs-2";

const CompareVolumeModal = ({
  currMapData,
  setCompareData,
  chartData,
  lineRef,
  compareVolumes,
  volCalcType,
  setVolCalcType,
  customHeight,
  setCustomHeight,
}) => {
  return (
    <>
      {currMapData && currMapData["visits"] && (
        <div className="row">
          <h4>Compare Volumes</h4>
          <div className="col-6">
            <MultiSelect
              data={currMapData["visits"]}
              title={"Visits"}
              onDataChange={setCompareData}
            />
          </div>
          <div className="col-6" style={{ marginTop: "20px" }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={volCalcType}
                label="Type"
                onChange={(e) => {
                  setVolCalcType(e.target.value);
                }}
              >
                <MenuItem value={"min-height"}>Minimum Height</MenuItem>
                <MenuItem value={"custom"}>Custom Height</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-4"></div>

          {volCalcType == "custom" && (
            <div className="col-4 mt-3 d-flex justify-content-center">
              <TextField
                value={customHeight}
                type="number"
                onChange={(e) => setCustomHeight(e.target.value)}
                id="outlined-basic"
                label="Height"
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{
                  style: { color: "rgba(0,0,0,.5)" },
                }}
              />
            </div>
          )}
          <div className="col-4"></div>
        </div>
      )}
      <div className="d-flex justify-content-center">
        <button className="btn btn-dark btn-sm my-2" onClick={compareVolumes}>
          Compare
        </button>
      </div>
      {true && <Line data={chartData} ref={lineRef} />}
    </>
  );
};

export default CompareVolumeModal;
