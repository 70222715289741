import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { mineData } from '../../data';
import Navbar from '../Navbar';

const CLOUD_STORAGE_PREFIX = "https://storage.googleapis.com/demo_adani/mine_sites/";

const Leaflet = (props) => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id')
    const idx = searchParams.get('idx')
    const currentData = mineData.find(el => el.id == id)
    window.goBackToPak = ()=>{
      console.log('pakistan!')
    }

  return (
    <>
    <Navbar setNavTitle={props.setNavTitle} navTitle={props.navTitle} />
    
      <iframe
          src={CLOUD_STORAGE_PREFIX+currentData.root+'/'+currentData.elevationPath[idx]}
          width="100%"
          height="100%"
      ></iframe>
    </>
  )
}

export default Leaflet
