import React from 'react'



const PDFTable = ({volumeData, latlng, visit, image}) => {
    return (
        <div >

            <div style={{ minWidth: "500px", minHeight: "500px", width: "100%", height: "500px", display: "flex", justifyContent: "center", margin: "20px" }}>
                <img src={"/"+image} alt="site" style={{ height: "100%" }} />
            </div>

            <div style={{ margin: "0 10px" }}>
                <div style={{ color: "grey", textAlign: "start" }}>
                    <strong>Note: </strong><span>The below calculations were done taking base value(ground value) as 0.</span>
                </div>
                <table className="table table-striped" style={{ textAlign: "start" }}>
                    <thead>
                        <tr style={{ textAlign: "center" }}><th colSpan="5" >Volumetric Analysis</th></tr>
                    </thead>
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Visit</th>
                            <th scope="col">Cut Volume (in m<sup>3</sup>)</th>
                            <th scope="col">Fill Volume (in m<sup>3</sup>)</th>
                            <th scope="col">Total Volume (in m<sup>3</sup>)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <th scope="row">{visit}</th>
                            <td>{volumeData.cut_volume}</td>
                            <td>{volumeData.fill_volume}</td>
                            <td>{volumeData.total_volume}</td>
                        </tr>
                    </tbody>
                </table>

                <div style={{display:"flex", gap:"10px"}}>
                    <table className="table table-striped" style={{ textAlign: "start" }}>
                        <thead>
                            <tr style={{ textAlign: "center" }}><th colSpan="5" >{visit} Volume Bounds</th></tr>
                        </thead>
                        <thead>
                            <tr>
                                <th scope="col">Latitude</th>
                                <th scope="col">Longitude</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                latlng?.map(coord=>{
                                    return (
                                        <tr key={coord[0].toString()+coord[1].toString()}>
                                            <td>{coord[0]}</td>
                                            <td>{coord[1]}</td>
                                        </tr>
                                    )
                                })
                            }
                            {/* <tr>
                                <td>6374</td>
                                <td>7643</td>
                            </tr>
                            <tr>
                                <td>2343</td>
                                <td>8348</td>
                            </tr>
                            <tr>
                                <td>2343</td>
                                <td>8348</td>
                            </tr>
                            <tr>
                                <td>2343</td>
                                <td>8348</td>
                            </tr> */}
                        </tbody>
                    </table>

                    {/* <table className="table table-striped" style={{ textAlign: "start" }}>
                        <thead>
                            <tr style={{ textAlign: "center" }}><th colSpan="5" >Visit 10 Bounding Points</th></tr>
                        </thead>
                        <thead>
                            <tr>
                                <th scope="col">Latitude</th>
                                <th scope="col">Longitude</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>6374</td>
                                <td>7643</td>
                            </tr>
                            <tr>
                                <td>2343</td>
                                <td>8348</td>
                            </tr>
                            <tr>
                                <td>2343</td>
                                <td>8348</td>
                            </tr>
                            <tr>
                                <td>2343</td>
                                <td>8348</td>
                            </tr>
                        </tbody>
                    </table> */}
                </div>
            </div>
        </div>
    )

}


export default PDFTable