import React, { useEffect, useRef } from "react";
import PDFTable from "../components/PDFTable";
import { useReactToPrint } from "react-to-print";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { mineData } from "../data";
// import { clearMissions, filterMissions, getLatestMission } from "../../slices/missionSlice";
// import moment from "moment";

const PdfViewer = () => {
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const currData = mineData.find((el) => el.id == searchParams.get("id"));
  const date = new Date();
  // const navigate = useNavigate()
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    setTimeout(() => {
      console.log(state);
    }, 500);
  }, []);
  return (
    <>
      <div
        style={{
          backgroundColor: "#2c2c2c",
          display: "flex",
          gap: "4px",
          justifyContent: "center",
        }}
      >
        <button
          className="btn btn-secondary btn-sm"
          onClick={() => window.history.back()}
        >
          back
        </button>
        <button
          className="btn btn-secondary btn-sm"
          onClick={() => handlePrint()}
        >
          Download PDF
        </button>
      </div>
      <div
        className=""
        ref={componentRef}
        style={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            backgroundColor: "#2c2c2c",
            height: "70px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 8px",
          }}
        >
          <div className="pdf_icon" style={{ margin: "8px" }}>
            <img
              src="/icons/logo.png"
              alt="logo"
              width="36px"
              style={{ filter: "invert()" }}
            />
          </div>

          <div
            className="pdf_subHeadings"
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            <table style={{ textAlign: "start", color: "white" }}>
              <tr>
                <td style={{ fontWeight: "bold" }}>Site:&nbsp;</td>
                <td style={{ color: "yellow" }}>{currData.name}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Date:&nbsp;</td>
                <td style={{ color: "yellow" }}>{`${date.getDate()} / ${
                  date.getMonth() + 1
                } / ${date.getFullYear()}`}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>
                  Report Generated By:&nbsp;
                </td>
                <td style={{ color: "yellow" }}>Admin</td>
              </tr>
            </table>
          </div>
        </div>
        <PDFTable
          latlng={state.coords}
          volumeData={{
            cut_volume: searchParams.get("cut_volume"),
            fill_volume: searchParams.get("fill_volume"),
            total_volume: searchParams.get("total_volume"),
          }}
          visit={currData.visits[searchParams.get("visitId")]}
          image={state.image}
        />
      </div>
    </>
  );
};

export default PdfViewer;
