import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TablePage from "./pages/TablePage";
import "leaflet/dist/leaflet.css";
import MapPage from "./pages/MapPage";
import MapView from "./pages/MapView";
import Navbar from "./components/Navbar";
import { useState } from "react";
import Login from "./pages/Login/index ";
import PotreePointcloud from "./components/PotreePointcloud";
import Leaflet from "./components/Leaflet";
import TestPotree from "./pages/TestPotree";
// import Splash from "./components/Splash";
import CompareMaps from "./pages/CompareMaps";
import PdfViewer from "./pages/PdfViewer";

function App() {
  const [view, setView] = useState("list");
  const [navTitle, setNavTitle] = useState("");
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* All guarded routes */}
          {/* <Route exact path="/" element={<LeafPage />} /> */}
          {/* <Route exact path="/" element={<TablePage />} /> */}
          <Route exact path="/" element={<Login />} />
          <Route
            exact
            path="/mapview"
            element={<MapView setNavTitle={setNavTitle} navTitle={navTitle} />}
          />
          <Route
            exact
            path="/maps/:id"
            element={<MapPage setNavTitle={setNavTitle} navTitle={navTitle} />}
          />
          <Route
            exact
            path="/maps3d"
            element={
              <PotreePointcloud setNavTitle={setNavTitle} navTitle={navTitle} />
            }
          />
          <Route
            exact
            path="/elevation"
            element={<Leaflet setNavTitle={setNavTitle} navTitle={navTitle} />}
          />
          {/* <Route exact path="/splash" element={<Splash/>} /> */}
          <Route exact path="/potrees" element={<TestPotree />} />
          <Route exact path="/cmaps/:id"  element={<CompareMaps setNavTitle={setNavTitle} navTitle={navTitle} />} />
          <Route exact path="/pdf"  element={< PdfViewer />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
