import React, { useEffect, useState } from "react";
import "./SitesSideBar.css";
import SiteCard from "./SiteCard";
import Hamburg from "../Hamburger";
import { mineData } from "../../data";
import { minesList } from "../../minesList";

const SitesSideBar = () => {

    const [isCollapsed, setIsCollapsed] = useState(false);

    const hideCard = () => {
        document.querySelector(".card_container").classList.toggle("closed");
        setIsCollapsed((prevState) => !prevState)
    };
    return (
        <div
            style={{
                zIndex: 99,
                position: "absolute",
                top: "8%",
                left: "-10px",
                // width: isCollapsed ? "10px" : "19rem",
                height: "85vh",
                // opacity: '1'
                boxShadow: "1px 1px 7px 1px grey",

            }}
            id="caard"
            className={`card_container rounded`}
        >
            <div
                href="#"
                style={{
                    // backgroundColor: "rgb(79 113 197)",
                    width: "20vw",
                    height: "85vh",
                    backgroundColor: "rgba(0,0,0,.5)"
                    // display: isCollapsed ? 'none' : 'block'
                }}
                className="block max-w-sm pl-6 pr-6 p-4 border-gray-200 shadow hover:bg-gray-100 overflow-scroll scrolling"
            >
                {/* <Hamburg hideCard={hideCard} isCollapsed={isCollapsed} /> */}
                {/* {minesList.map((mine) =>
                    <SiteCard detail={mine} />
                )} */}
                {mineData.map((mine) =>
                    <SiteCard detail={mine} />
                )}
            </div>
        </div>
    );
};

export default SitesSideBar;