import { useEffect, useRef, useState } from "react";
import useBasicViewerConfig from "../hooks/useBasicViewerConfig";
import useLoadPointcloud from "../hooks/useLoadPointcloud.js";
import SwitchDim from "./SwitchDim/index.jsx";
import { useSearchParams } from "react-router-dom";
import Navbar from "./Navbar/index.jsx";
// const { IpcRenderer} = window.require('electron') ;

export default function PotreePointcloud(props) {
  const [searchParams] = useSearchParams();
  const pcdPath = searchParams.get('path')
  const id = searchParams.get('id')
  const iframe = useRef(null);
  const potreeLib = useRef(null);
  const potreeViewer = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [path, setPath] = useState(null);
  const [screenLoaded, setScreenLoaded] = useState(false)

  // initialize a reference to the Potree lib and viewer
  useEffect(() => {
    console.log("pcddddddddddddddd", pcdPath, id)
    if (iframe.current && loaded) {
      potreeLib.current = iframe.current.contentDocument.defaultView.Potree;
      potreeViewer.current = iframe.current.contentDocument.defaultView.viewer;
      console.log(potreeLib.current)
      loadEverything();
    }
    setScreenLoaded(true)
  }, [loaded]);

  // viewer config
  useBasicViewerConfig(loaded, potreeLib, potreeViewer);

  // load pointcloud
  useLoadPointcloud(
    loaded,
    potreeLib,
    potreeViewer,
    `./pointclouds/${pcdPath}/metadata.json`,
    // `./pointclouds/landsite1/metadata.json`,
    // "http://localhost:5000"+path,
    "sigeom",
    true
  );

  const loadEverything = (name, event)=>{
    console.log(event)
    // fetch("http://localhost:5000/test", {
    // method:"GET",  
    // headers:{
    //     "Content-Type":"application/json"
    //   }
    // }).then(resp=> resp.json())
    // .then(data=>{
    //   setPath(data.path)
    //   setLoaded(true)
    // }).catch(err=>{
    //   console.log(err.message)
    // })
    // setPath("pcd")
    // IpcRenderer.send('button-click', name)
    // window.Electron.ipcRenderer.send('button-click', name)
  }

  return (
    <>
            <Navbar setNavTitle={props.setNavTitle} navTitle={props.navTitle} />

    <div style={potreePointcloudStyle}>
      <SwitchDim currMapData={{id, modelPath:path}} />
      <iframe
        title="3D Pointcloud"
        id="potreeIframe"
        src="/potree/viewer.htm"
        ref={iframe}
        style={iframeStyle}
        onLoad={() => setLoaded(true)}
      />
    </div>
    </>
  );
}

const potreePointcloudStyle = {
  display: "flex",
  height: "100vh",
  width: "100vw",
  margin: 0,
  padding: 0,
};

const iframeStyle = {
  width: "100%",
  height: "100%",
  border: 0,
};
