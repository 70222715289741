import { useEffect } from "react";

export default function useLoadPointcloud(
  loaded,
  potreeLibRef,
  potreeViewerRef,
  pointcloudURL,
  pointcloudTitle,
  fitToScreen = false
) {
  useEffect(() => {
    if (loaded && potreeViewerRef.current && potreeLibRef.current) {
      const Potree = potreeLibRef.current;
      const viewer = potreeViewerRef.current;
      const p = new Promise((res)=>{
        Potree.loadPointCloud(pointcloudURL, pointcloudTitle, (e) => {
          let scene = viewer.scene;
          let pointcloud = e.pointcloud;
          // var newPosition = new THREE.Vector3(x, y, z); 
          let material = pointcloud.material;
          material.size = 1;
          material.pointSizeType = Potree.PointSizeType.FIXED;
          material.shape = Potree.PointShape.CIRCLE;
  
          scene.addPointCloud(pointcloud);
  
          fitToScreen && viewer.fitToScreen();
          setTimeout(() => {
            viewer.setTopView()
            viewer.setFOV(30)
            // viewer.scene.view.position.y += 1;
  
            // viewer.scene.cameraO.position.y += 1;
            // viewer.scene.cameraP.updateMatrixWorld(true);
            // viewer.scene.cameraO.updateMatrixWorld(true);
  
            // Render the scene to reflect the changes
            // viewer.update();
          }, 200);
          // var rotationY = Math.PI / 4; 
          // var rotationMatrix = new THREE.Matrix4();
          // rotationMatrix.makeRotationZ(rotationY)
          // viewer.scene.cameraP.position.applyMatrix4(rotationMatrix);
          // viewer.scene.cameraP.updateMatrixWorld(true);
          // viewer.update();
  
          console.log(`added pointcloud ${pointcloudTitle}`);
        });
        res('resolved')
      })
      p.then(()=>{
        console.log('resolved')
      })
    }
  }, [
    fitToScreen,
    loaded,
    pointcloudTitle,
    pointcloudURL,
    potreeLibRef,
    potreeViewerRef,
  ]);
}
