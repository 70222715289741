import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import MultiSelect from "../MultiSelect";
import { Line } from "react-chartjs-2";
import { IoCloseSharp } from "react-icons/io5";

const ContourCreationModal = ({
  setContourInterval,
  contourInterval,
  addContour,
}) => {
  return (
    <>
      <div className="row">
        <div className="row">
          <div className="col-11">
            <h4>Add New Contour</h4>
          </div>
          <div className="col-1">
          </div>
        </div>

        <div className="mt-2">
          <TextField
            value={contourInterval}
            onChange={(e) => setContourInterval(e.target.value)}
            id="outlined-basic"
            label="Interval"
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            InputLabelProps={{
              style: { color: "rgba(0,0,0,.5)" },
            }}
          />
          <div className="d-flex justify-content-center">
            <button className="btn btn-dark btn-sm my-2" onClick={addContour}>
              Create Contour
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContourCreationModal;
