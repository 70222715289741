import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { IoHome } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const Hamburg = ({ hideCard, isCollapsed }) => {
    return (
        <div
            style={{
                borderRadius: "5px",
                width: "40px",
                height: "28px",
                position: "absolute",
                zIndex: 9999,
                top: "9%",
                left:"1%",
                color:isCollapsed?"white":"black",
                backgroundColor:isCollapsed?"black":"rgba(0,0,0,0)",
                cursor:"pointer"
                // opacity:!isCollapsed?0.5:1
            }}
            className="h-screen flex items-center justify-center"
            onClick={() => hideCard()}
        >
            <MenuIcon />
        </div>
    );
};

const HomeBtn = () => {
    const navigate = useNavigate();
    return (
        <div
            style={{
                borderRadius: "5px",
                width: "40px",
                height: "28px",
                position: "absolute",
                zIndex: 2,
                top: "9%",
                left:"4%",
                color:"white",
                backgroundColor:"black",
                cursor:"pointer"
                // opacity:!isCollapsed?0.5:1
            }}
            className="h-screen flex items-center justify-center"
            onClick={() => navigate('/mapview')}
        >
            <IoHome />
        </div>
    );
};

export {Hamburg, HomeBtn};
