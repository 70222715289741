import React, { useEffect, useRef, useState } from "react";
import { mineData } from "../data";
import "chart.js/auto";
import { Line } from "react-chartjs-2";
// import { Chart } from 'react-chartjs-2'
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import Card from "../components/Card";
import Toolbar from "../components/Toolbar";
import { Hamburg, HomeBtn } from "../components/Hamburger";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import SwitchDim from "../components/SwitchDim";
import MapSwitch from "../components/MapSwitch";
import Spinner from "../components/Spinner";
import axios from "axios";
import { Box, Modal } from "@mui/material";
import MultiSelect from "../components/MultiSelect";
import CompareVolumeModal from "../components/CompareVolumeModal";
import CalculateVolumeModal from "../components/CalculateVolumeModal";
import ContourCreationModal from "../components/ContourCreationModal";

const SERVER_URL = "https://api.phiscan.ai";
const CLOUD_STORAGE_PREFIX = "https://storage.googleapis.com/demo_adani/mine_sites/";

const mapStyles = {
  width: "100%",
  height: "100%",
  position: "relative",
};

const skeletonChartData = {
  labels: [],
  datasets: [
    {
      label: "Cut Volume",
      data: [],
      fill: true,
      borderColor: "rgba(75,192,192,1)",
    },
    {
      label: "Fill Volume",
      data: [],
      fill: true,
      borderColor: "#742774",
    },
    {
      label: "Total Volume",
      data: [],
      fill: true,
      borderColor: "#FD7272",
    },
  ],
};

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  minHeight: "25%",
  transform: "translate(-50%, -50% )",
  width: "60%",
  minWidth: "500px",
  borderRadius: 1,
  bgcolor: "background.paper",
  p: 4,
};

const MapPage = (props) => {
  const { id, idx } = useParams();
  const [detectChange, setDetectChange] = useState(false);
  const [currMapData, setCurrMapData] = useState({});
  const [map, setMap] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [contours, setContours] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const mapRef = useRef(null);
  const navigate = useNavigate();
  const [contourPath, setContourPath] = useState([]);
  const [compareData, setCompareData] = useState([]);
  const [coords, setCoords] = useState([]);
  const [volCalcType, setVolCalcType] = useState("min-height");
  const [chartData, setChartData] = useState({ ...skeletonChartData });
  const [modalType, setModalType] = useState(null);
  const [volumeData, setVolumeData] = useState(null);
  const lineRef = useRef(null);
  // const contourPath = mineData.find;
  // const contourPath = ["output1.geojson", "output2.geojson"];
  const [area, setArea] = useState(null);
  const [customHeight, setCustomHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [geoData, setGeoData] = useState(null);
  const [checkedRadio, setCheckedRadio] = useState(0);
  const [checkedRadioMap, setCheckedRadioMap] = useState(0);
  const [savedPolyPoints, setSavedPolyPoints] = useState({
    polylines: [],
    polygons: [],
  });
  const [createdContours, setCreatedContours] = useState({
    1: {
      "9th Visit": {
        20: "1709626448_553.geojson",
      },
    },
  });
  const [currMapTypeIndex, setCurrMapTypeIndex] = useState(0);

  const [contourInterval, setContourInterval] = useState(0);
  const removeContour = () => {
    mapRef.current.map.data.forEach((feature) => {
      mapRef.current.map.data.remove(feature);
    });
  };

  useEffect(() => {
    if (lineRef.current) {
      console.log("rendering");
      lineRef.current.render();
      lineRef.current.update();
    }
  }, [chartData]);

  useEffect(() => {
    const localContData = JSON.parse(localStorage.getItem("contours"));
    setCreatedContours(localContData);
  }, []);

  const createContour = async (i, color) => {
    console.log(contourPath);
    setLoading(true);
    console.log("before");
    removeContour();
    const p = new Promise((res) => {
      setTimeout(() => {
        mapRef.current.map.data.loadGeoJson("/assets/" + contourPath[i]);
        mapRef.current.map.data.setStyle({
          strokeColor: color,
          strokeWeight: 1,
        });
        res(true);
      }, 500);
    });
    p.then(() => {
      setLoading(false);
      console.log("after");
    });
  };

  const renderContour = async (fileName) => {
    setLoading(true);
    console.log("before");
    removeContour();
    // console.log({fileName});
    const p = new Promise((res) => {
      setTimeout(() => {
        mapRef.current.map.data.loadGeoJson(
          `${SERVER_URL}/static/geojsons/${fileName}`
        );
        mapRef.current.map.data.setStyle({
          strokeColor: "white",
          strokeWeight: 1,
        });
        setLoading(false);
        res(true);
      }, 500);
    });
    p.then(() => {
      console.log("after");
    }).catch((err) => setLoading(false));
  };
  const handleClickOnExportPDF = () => {
    const data = {
      id,
      ...volumeData,
      visitId: window.currentMapTypeIndex,
    };
    const query = new URLSearchParams(data).toString();
    console.log({ data, query });
    navigate("/pdf?" + query, {
      state: { coords, image:currMapData.image },
    });
  };

  const hideCard = () => {
    document.querySelector(".card_container").classList.toggle("closed");
    setIsCollapsed(!isCollapsed);
  };

  const calculateVolume = async () => {
    const body = {
      poly_coords: coords,
      baseValue: customHeight,
      type: volCalcType,
      visit: currMapData.visits[window.currentMapTypeIndex],
    };
    setVolumeData(null);
    setLoading(true);
    try {
      const { data } = await axios.post(`${SERVER_URL}/vol/${id}`, body, {
        timeout: 60000,
      });
      if (data.status === "success") {
        setLoading(false);
        const tempVol = {
          cut_volume: data.data.cut_volume * 1,
          fill_volume: data.data.fill_volume * 1,
          total_volume: (data.data.cut_volume * 1 + data.data.fill_volume * 1).toFixed(2),
        };
        setVolumeData(tempVol);
      } else if (data.status === "failure") {
        alert(data.message);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      alert(err.toString());
    }
  };

  const downloadContour = async (fileName) => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = `${SERVER_URL}/static/contours/${fileName}/${fileName}.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const addContour = async () => {
    if (contourInterval <= 0) alert("please enter valid interval");
    const body = {
      interval: contourInterval,
      visit: currMapData.visits[window.currentMapTypeIndex],
    };
    if (
      createdContours &&
      createdContours[id] &&
      createdContours[id][currMapData.visits[window.currentMapTypeIndex]] &&
      createdContours[id][currMapData.visits[window.currentMapTypeIndex]][
        contourInterval
      ]
    )
      return alert("contour already present");
    setLoading(true);
    try {
      const { data } = await axios.post(`${SERVER_URL}/cont/${id}`, body, {
        timeout: 60000,
      });
      if (data.status === "success") {
        setLoading(false);
        closeModal();

        let len = 0;
        setCreatedContours((prevState) => {
          // 1: {
          //   "9th Visit": {
          //     20: "1709626448_553.geojson",
          //   },
          // },
          // let temp = { [contourInterval]: data.data.name };
          // createdContours[id][currMapData.visits[window.currentMapTypeIndex]][contourInterval]
          let temp = { ...prevState };
          if (!temp[id]) {
            temp[id] = {};
          }
          if (!temp[id][currMapData.visits[window.currentMapTypeIndex]]) {
            temp[id][currMapData.visits[window.currentMapTypeIndex]] = {};
          }
          temp[id][currMapData.visits[window.currentMapTypeIndex]][
            contourInterval
          ] = data.data.name;
          // len = Object.keys(temp[id][currMapData.visits[window.currentMapTypeIndex]]).length
          len = Object.keys(
            temp[id][currMapData.visits[window.currentMapTypeIndex]]
          ).indexOf(contourInterval);
          localStorage.setItem("contours", JSON.stringify(temp));
          return temp;
          // return { ...prevState, ...temp };
        });
        setTimeout(() => {
          setCheckedRadio(len + 3);
        }, 10);
        renderContour(data.data.name);
      } else if (data.status === "failure") {
        alert(data.message);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      alert(err.toString());
    }
  };

  const compareVolumes = async () => {
    const tempChartData = {
      labels: [],
      datasets: [
        {
          ...chartData.datasets[0],
          data: [],
        },
        {
          ...chartData.datasets[1],
          data: [],
        },
        {
          ...chartData.datasets[2],
          data: [],
        },
      ],
    };
    // return
    if (compareData < 2 || coords.length < 3) {
      return;
    }
    const body = {
      poly_coords: coords,
      baseValue: customHeight,
      type: volCalcType,
      visits: compareData,
    };
    setLoading(true);
    try {
      const { data: respData } = await axios.post(
        `${SERVER_URL}/vol/${id}/compare`,
        body,
        { timeout: 60000 }
      );
      console.log({ respData });
      if (respData.status === "success") {
        respData["data"]
          ? respData["data"].map((el) => {
              tempChartData.labels.push(el.visit);
              tempChartData.datasets[0].data.push(el.volume.cut_volume);
              tempChartData.datasets[1].data.push(el.volume.fill_volume);
              tempChartData.datasets[2].data.push(
                el.volume.cut_volume * 1 + el.volume.fill_volume * 1
              );
              return el.visit;
            })
          : alert(respData.message || "Something went wrong");
        setLoading(false);
        setChartData(tempChartData);
      } else if (respData.status === "failure") {
        alert(respData.message);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      alert(err.toString());
    }
  };

  const openModal = (type) => {
    setModalType(type);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalType(null);
    setVolumeData(null);
    setCompareData([]);
    setContourInterval(0);
    setChartData({
      labels: [],
      datasets: [
        {
          ...chartData.datasets[0],
          data: [],
        },
        {
          ...chartData.datasets[1],
          data: [],
        },
        {
          ...chartData.datasets[2],
          data: [],
        },
      ],
    });
    setVolCalcType("min-height");
    setCustomHeight(0);
  };

  useEffect(() => {
    const mapParams = mineData.find((el) => el.id == id);
    setCurrMapData(mapParams);
    setContourPath(mapParams.geoPaths);
    props.setNavTitle(mapParams);
    const map = mapRef.current.map;
    map.setOptions({
      maxZoom:mapParams.maxZoom
    })
    window.currentMapTypeIndex = 0;
    window.currentMapType = "orthoPaths";
    let drawingManager = null;
    let features = null;
    let drawnPolygons = [];
    let drawnPolylines = [];
    // map.setMapTypeId("satellite");
    setMap(map);
    const google = props.google;

    var mapBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(
        mapParams.mapBounds.lat1,
        mapParams.mapBounds.lng1
      ),
      new google.maps.LatLng(mapParams.mapBounds.lat2, mapParams.mapBounds.lng2)
      // new google.maps.LatLng(23.92009928471577, 82.29913453439386),
      // new google.maps.LatLng(23.94689019999705, 82.36428002694757)
    );
    map.fitBounds(mapBounds);
    // map.setZoom()
    // setMapCenter(mapParams.location)
    console.log("mapss", mapParams);
    map.setCenter(mapParams.location);
    if (geoData) {
      console.log("geoData", geoData);
      // features = map.data.addGeoJson(geoData)
    }
    async function getGeoData() {
      try {
        // if(drawnPolygons.length > 0 || drawnPolylines.length > 0) window.removeDrawnPoly();
        // const resp = await fetch("http://localhost:4000/geo/"+id);
        // const {polygons, polylines} = await resp.json();
        const resp = localStorage.getItem("polyFeatures") ?? "[]";
        const curPolyData = JSON.parse(resp).find((el) => el.id == id);
        if (curPolyData) {
          const { polygons, polylines } = curPolyData;
          // setGeoData(geoParams)
          for (let i = 0; i < polygons.features.length; i++) {
            console.log(polygons.features[i]);
            const polygon = new google.maps.Polygon({
              path: polygons.features[i].geometry.coordinates.map((latlng) => ({
                lat: latlng[1],
                lng: latlng[0],
              })),
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              id: i + 1,
            });
            polygon.setMap(map);
            drawnPolygons.push(polygon);
            console.log(drawnPolygons);
          }
          for (let i = 0; i < polylines.features.length; i++) {
            console.log(polylines.features[i]);
            const polyline = new google.maps.Polyline({
              path: polylines.features[i].geometry.coordinates.map(
                (latlng) => ({ lat: latlng[1], lng: latlng[0] })
              ),
              strokeColor: "blue",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              id: i + 1,
            });
            polyline.setMap(map);
            drawnPolylines.push(polyline);
            console.log(drawnPolylines);
          }
          setSavedPolyPoints({
            polygons: drawnPolygons,
            polylines: drawnPolylines,
          });
        }
      } catch (error) {
        alert(error.message);
      }
    }
    getGeoData();

    console.log("drawn", drawnPolygons);
    // map.data.loadGeoJson('/assets/mapdata2.geojson')

    const drawnFeatures = [];
    const drawnFeatures1 = [];
    const initDrawingManager = () => {
      drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.MARKER,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.POLYLINE,
          ],
          markerOptions: {
            editable: true,
          },
        },
        markerOptions: {
          icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
        },

        polygonOptions: {
          strokeColor: "red",
          strokeWeight: 2,
          strokeOpacity: 1,
          // Define the dashed pattern here
          // icons: [
          //     {
          //         // icon: {
          //         //     path: "M 0,-1 0,1",
          //         //     strokeOpacity: 1,
          //         //     scale: 4,
          //         // },
          //         offset: "0",
          //         repeat: "20px",
          //     },
          // ],
        },
        polylineOptions: {
          strokeColor: "blue",
          strokeWeight: 2,
          strokeOpacity: 1,
        },
        map: map,
      });

      drawingManager.setMap(map);

      google.maps.event.addListener(
        drawingManager,
        "overlaycomplete",
        function (event) {
          if (event.type === "polygon") {
            const path = event.overlay
              .getPath()
              .getArray()
              .map((p) => ({ lat: p.lat(), lng: p.lng() }));
            console.log(path);
            let feature = {
              type: "Feature",
              properties: {}, // Any properties you want to add
              geometry: {
                type: "LineString",
                coordinates: path.map((p) => [p.lng, p.lat]),
              },
            };

            drawnFeatures.push(feature);
          }
          if (event.type === "polyline") {
            const path = event.overlay
              .getPath()
              .getArray()
              .map((p) => ({ lat: p.lat(), lng: p.lng() }));
            console.log(path);
            let feature = {
              type: "Feature",
              properties: {}, // Any properties you want to add
              geometry: {
                type: "LineString",
                coordinates: path.map((p) => [p.lng, p.lat]),
              },
            };

            drawnFeatures1.push(feature);
          }
        }
      );
    };

    window.exportGeoJson = () => {
      const geoJson = {
        type: "FeatureCollection",
        features: drawnFeatures,
      };
      downloadGeoJson(geoJson);
    };
    window.saveToDb = () => {
      console.log(drawnFeatures);
      let geoJsonPolygon = {},
        geoJsonPolylines = {};
      if (drawnFeatures) {
        geoJsonPolygon = {
          type: "FeatureCollection",
          features: drawnFeatures,
        };
        //   localStorage.setItem("featureCollection", JSON.stringify(geoJson))
        // fetch("http://localhost:4000/geo", {method:"POST", headers:{"Content-Type":"application/json"}, body:JSON.stringify({geoParams:geoJson, id})})
        // .then(resp=> resp.json())
        // .then(d => {
        //     alert("Drawings saved")
        // })
      }
      if (drawnFeatures1) {
        geoJsonPolylines = {
          type: "FeatureCollection",
          features: drawnFeatures1,
        };
        //   localStorage.setItem("featureCollection", JSON.stringify(geoJson))
      }
      let savedLocalData = localStorage.getItem("polyFeatures");
      if (!savedLocalData) {
        localStorage.setItem(
          "polyFeatures",
          JSON.stringify([
            { polygons: geoJsonPolygon, polylines: geoJsonPolylines, id },
          ])
        );
      } else {
        let parsedLocal = JSON.parse(savedLocalData);
        const exists = parsedLocal.find((el) => el.id == id);
        console.log(exists);
        if (exists) {
          parsedLocal = parsedLocal.map((el) => {
            if (el.id == id) {
              return {
                ...el,
                polygons: geoJsonPolygon,
                polylines: geoJsonPolylines,
              };
            }
            return el;
          });
        } else {
          parsedLocal.push({
            polygons: geoJsonPolygon,
            polylines: geoJsonPolylines,
            id,
          });
        }
        localStorage.setItem("polyFeatures", JSON.stringify(parsedLocal));
      }
      alert("Drawigs saved!");
      // fetch("http://localhost:4000/geo", {method:"POST", headers:{"Content-Type":"application/json"}, body:JSON.stringify({polygons:geoJsonPolygon, polylines:geoJsonPolylines, id})})
      // .then(resp=> resp.json())
      // .then(d => {
      //     alert("Drawings saved")
      // })

      // getGeoData();
    };

    window.removeDrawnPoly = () => {
      console.log("yo", drawnPolygons);
      drawnPolygons?.forEach((polygon) => {
        console.log("polys", polygon);
        polygon.setMap(null);
        polygon = null;
      });
      drawnPolylines?.forEach((polyline) => {
        polyline.setMap(null);
        polyline = null;
      });
      drawnPolylines = [];
      drawnPolygons = [];
    };

    function downloadGeoJson(geoJson) {
      const dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(geoJson));
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", "map_data.geojson");
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    }
    // initDrawingManager();
    window.toggleDraw = () => {
      if (drawingManager) {
        drawingManager.setMap(null);
        drawingManager = null;
      } else {
        initDrawingManager();
      }
    };

    const overlayMapTiles = (index = 0, mapType = "orthoPaths") => {
      const imageMapType = new google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          console.log(mapType, index, mapParams[mapType][index]);
          var proj = map.getProjection();
          // console.log(coord, zoom);
          var tileSize = 256 / Math.pow(2, zoom);
          var tileBounds = new google.maps.LatLngBounds(
            proj.fromPointToLatLng(
              new google.maps.Point(
                coord.x * tileSize,
                (coord.y + 1) * tileSize
              )
            ),
            proj.fromPointToLatLng(
              new google.maps.Point(
                (coord.x + 1) * tileSize,
                coord.y * tileSize
              )
            )
          );
          if (mapBounds.intersects(tileBounds)) {
            return [
              CLOUD_STORAGE_PREFIX +
                mapParams.root +
                "/maps/" +
                mapParams[mapType][index] +
                "/",
              zoom,
              "/",
              coord.x,
              "/",
              Math.pow(2, zoom) - coord.y - 1,
              ".png",
            ].join("");
          }
          return "";
        },
        tileSize: new google.maps.Size(256, 256),
      });
      map.overlayMapTypes.push(imageMapType);
    };

    const removeOverlayTiles = () => {
      map.overlayMapTypes.forEach((el, i) => {
        map.overlayMapTypes.removeAt(i);
      });
    };

    overlayMapTiles(idx);
    // window.remove
    window.chnageMapByDate = (index, mapType = "orthoPaths") => {
      removeOverlayTiles();
      removeOverlayTiles();
      window.currentMapTypeIndex = index;
      setCurrMapTypeIndex(index);
      overlayMapTiles(window.currentMapTypeIndex, mapType);
      setDetectChange((prev) => !prev);
    };

    window.changeMap = (index, mapType) => {
      removeOverlayTiles();
      removeContour();
      overlayMapTiles(window.currentMapTypeIndex, mapType);
      window.currentMapType = mapType;
    };

    let mapClickedA = null;
    let mapClickedD = null;
    let aCalInitiated = false;
    let dCalInitiated = false;

    let markers = [];
    let distMarkers = [];
    let volCoords = [];
    let polygon = null;
    // ----------------------------------------//
    window.startAreaCal = () => {
      if (dCalInitiated) return;
      try {
        aCalInitiated = true;
        mapClickedA = map.addListener("click", (mapsMouseEvent) => {
          const marker = new google.maps.Marker({
            position: mapsMouseEvent.latLng,
            map: map,
            icon: "/dot16.png",
          });
          markers.push(marker);
          if (polygon) {
            polygon.setMap(null);
          }
          drawPolygon();
          console.log({ mapsMouseEvent });
          volCoords.push([
            mapsMouseEvent.latLng.lat(),
            mapsMouseEvent.latLng.lng(),
          ]);
          console.log({ volCoords });
          // volCoords.push([mapsMouseEvent.latLng.lat(),mapsMouseEvent.latLng.lng()])
          // console.log({volCoords});
          setCoords((prevState) => [
            ...prevState,
            [mapsMouseEvent.latLng.lat(), mapsMouseEvent.latLng.lng()],
          ]);
        });
        const drawPolygon = () => {
          const path = markers.map((marker) => marker.getPosition());

          polygon = new google.maps.Polygon({
            paths: path,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
          });

          polygon.setMap(map);

          // Calculate and log the area of the polygon
          if (google.maps.geometry && polygon) {
            const area = google.maps.geometry.spherical.computeArea(
              polygon.getPath()
            );
            document.getElementById("display-label").innerHTML = "-Area-";
            const areaDiv = document.getElementById("display");
            areaDiv.innerHTML = area.toFixed(2) + " m<sup>2</sup>";
          }
        };
      } catch (error) {
        console.log("error");
      }
    };

    let polyline = null;
    let lineMarkers = [];
    window.startLineCal = () => {
      if (aCalInitiated) return;
      try {
        dCalInitiated = true;
        polyline = new google.maps.Polyline({
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 3,
          map: map,
        });

        mapClickedD = map.addListener("click", (event) => {
          console.log({ lngLt: event.latLng });
          addMarker(event.latLng);
          if (lineMarkers.length >= 1) {
            updatePolyline();
            calculateTotalDistance();
            displayDistance();
          }
        });

        function addMarker(location) {
          const marker = new google.maps.Marker({
            position: location,
            map: map,
            icon: "/dot16.png",
          });

          lineMarkers.push(marker);
        }

        function updatePolyline() {
          if (polyline) {
            const path = polyline.getPath();
            path.push(lineMarkers[lineMarkers.length - 1].getPosition());
          }
        }

        let totalDistance = 0;
        function calculateTotalDistance() {
          // console.log("path", polyline.getPath());
          if (lineMarkers.length > 1) {
            if (!polyline) {
              polyline = polyline = new google.maps.Polyline({
                strokeColor: "#FF0000",
                strokeOpacity: 1.0,
                strokeWeight: 3,
                map: map,
              });
            }
            const path = polyline.getPath();
            // console.log({path});
            const lastPolyLongLat = {
              lat: path.Fg[path.Fg.length - 1].lat(),
              lng: path.Fg[path.Fg.length - 1].lng(),
            };
            const beforeLastPolyLongLat = {
              lat: path.Fg[path.Fg.length - 2].lat(),
              lng: path.Fg[path.Fg.length - 2].lng(),
            };
            const newPolyPos = {
              lat: (lastPolyLongLat.lat + beforeLastPolyLongLat.lat) / 2,
              lng: (lastPolyLongLat.lng + beforeLastPolyLongLat.lng) / 2,
            };
            // const lastPolyLongLat = path.Fg[path.Fg.length - 1]
            const len = google.maps.geometry.spherical.computeDistanceBetween(
              path.getAt(path.Fg.length - 1),
              path.getAt(path.Fg.length - 2)
            );
            // debugger
            console.log({ len });
            const marker = new google.maps.Marker({
              position: newPolyPos,
              map: map,
              label: {
                color: " #e6eeff",
                fontWeight: "bold",
                fontSize: "17px",
                className: 'marker-label',
                text: `${len.toFixed(2)} m`,
              },
              icon: "/trans.png",
            });
            distMarkers.push(marker);
            totalDistance = 0;
            for (let i = 0; i < path.getLength() - 1; i++) {
              totalDistance +=
                google.maps.geometry.spherical.computeDistanceBetween(
                  path.getAt(i),
                  path.getAt(i + 1)
                );
            }
          }
        }
        function displayDistance() {
          console.log(`Total Distance: ${totalDistance.toFixed(2)} meters`);
          const distDiv = document.getElementById("display");
          distDiv.innerText = totalDistance.toFixed(2) + " m";
          document.getElementById("display-label").innerText = "Distance";
          // Alternatively, you can display this distance in an HTML element
        }
      } catch (error) {
        console.log(error);
      }
    };
    window.resetPolys = () => {
      console.log(mapClickedA, mapClickedD);
      setCoords([]);
      if (true) {
        for (let marker of lineMarkers) {
          marker.setMap(null);
        }
        for (let marker of distMarkers) {
          marker.setMap(null);
        }

        lineMarkers = [];
        distMarkers = [];
        if (polyline) {
          polyline.setMap(null);
          polyline = null;
        }
        dCalInitiated = false;
        google.maps.event.removeListener(mapClickedD);
        mapClickedD = null;
        // lineDiv.innerText = "Measure Distance";
        const mesDistDiv = document.getElementById("display");
        mesDistDiv.innerText = "-";
      }
      if (true) {
        for (let marker of markers) {
          marker.setMap(null);
        }
        // Clear the markers array
        markers = [];

        // Remove the polygon if it exists
        if (polygon) {
          polygon.setMap(null);
          polygon = null;
        }
        aCalInitiated = false;
        google.maps.event.removeListener(mapClickedA);
        mapClickedA = null;
        const mesAreaDiv = document.getElementById("display");
        mesAreaDiv.innerText = "-";
      }
      // window.location.reload();
      return true;
    };
  }, [props.google, contours]);
  return (
    <>
      <Modal
        open={modalOpen}
        onClose={() => {
          closeModal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...modalStyle,
            width: modalType === "compare" ? "60%" : "30%",
          }}
        >
          {modalType == "compare" ? (
            <CompareVolumeModal
              chartData={chartData}
              compareVolumes={compareVolumes}
              currMapData={currMapData}
              lineRef={lineRef}
              setCompareData={setCompareData}
              setVolCalcType={setVolCalcType}
              volCalcType={volCalcType}
              setCustomHeight={setCustomHeight}
              customHeight={customHeight}
            />
          ) : modalType == "calculate" ? (
            <CalculateVolumeModal
              calculateVolume={calculateVolume}
              customHeight={customHeight}
              setCustomHeight={setCustomHeight}
              setVolCalcType={setVolCalcType}
              volCalcType={volCalcType}
              volumeData={volumeData}
              handleClickOnExportPDF={handleClickOnExportPDF}
            />
          ) : modalType == "add-contour" ? (
            <ContourCreationModal
              contourInterval={contourInterval}
              setContourInterval={setContourInterval}
              addContour={addContour}
            />
          ) : null}
        </Box>
      </Modal>
      <Navbar setNavTitle={props.setNavTitle} navTitle={props.navTitle} />
      {loading && (
        <div
          style={{
            background: "rgba(255, 255, 255, 0.5)",
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "999999",
            position: "absolute",
          }}
        >
          <Spinner />
        </div>
      )}
      <Map
        google={props.google}
        zoom={15}
        style={mapStyles}
        streetViewControl={false}
        // initialCenter={mapCenter}
        mapTypeControl={true}
        disableDefaultUI={true}
        ref={mapRef}
        minZoom={15}
        drawingControl={true}
        mapTypeControlOptions={{
          position: props.google.maps.ControlPosition.TOP_RIGHT,
        }}
      ></Map>
      <div
        id="area-dist"
        className="container rounded"
        style={{
          position: "absolute",
          zIndex: "9999",
          bottom: "3%",
          right: "3.5%",
          backgroundColor: "white",
          width: "100px",
          height: "50px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div id="display-label" className="label">
          -
        </div>
        <div id="display" style={{ fontWeight: "bold" }}>
          -
        </div>
      </div>

      <Hamburg hideCard={hideCard} isCollapsed={isCollapsed} />
      <HomeBtn />
      <Toolbar
        startLineCal={window.startLineCal}
        startAreaCal={window.startAreaCal}
        resetPolys={window.resetPolys}
        getContour1={() => createContour(0, "white")}
        getContour2={() => createContour(1, "red")}
        clearContour={removeContour}
        mapId={id}
        currentMapTypeIndex={window.currentMapTypeIndex}
        toggleDraw={window.toggleDraw}
        exportGeoJson={window.exportGeoJson}
        saveToDb={window.saveToDb}
        removeDrawnPoly={window.removeDrawnPoly}
      />
      <SwitchDim currMapData={currMapData} />
      {coords.length && (
        <nav
          className="navbar navbar-expand-lg navbar-light bg-dark-o rounded"
          style={{
            position: "absolute",
            zIndex: 2,
            top: "25%",
            right: "2%",
            padding: "5px",
          }}
        >
          <div className="d-flex gap-1 flex-row">
            <div className="btn-group ">
              <button
                className="btn btn-dark btn-sm"
                disabled={coords.length < 3}
                onClick={() => openModal("calculate")}
              >
                Calculate Volume
              </button>
            </div>
            <div>
              <button
                className="btn btn-dark btn-sm "
                disabled={coords.length < 3}
                onClick={() => openModal("compare")}
              >
                Compare Volumes
              </button>
            </div>
          </div>
        </nav>
      )}
      <MapSwitch
        getMap1={window.chnageMapByDate}
        currentMapType={window.currentMapType}
        currentMapTypeIndex={window.currentMapTypeIndex}
        clearContour={removeContour}
        currMapData={currMapData}
        style={{ right: "8%", top: "20.5%" }}
      />
      <Card
        downloadContour={downloadContour}
        renderContour={renderContour}
        visitId={
          currMapData && currMapData.visits && currMapData.visits.length
            ? currMapData.visits[window.currentMapTypeIndex]
            : null
        }
        createdContours={createdContours}
        getContour1={() => createContour(0, "white")}
        getContour2={() => createContour(1, "red")}
        clearContour={removeContour}
        getMap1={() => window.chnageMap(1)}
        getMap2={() => window.chnageMap(0)}
        changeMap={window.changeMap}
        currentMapTypeIndex={window.currentMapTypeIndex}
        currentMapType={window.currentMapType}
        detectChange={detectChange}
        loading={loading}
        openModal={openModal}
        checkedRadio={checkedRadio}
        setCheckedRadio={setCheckedRadio}
        checkedRadioMap={checkedRadioMap}
        setCheckedRadioMap={setCheckedRadioMap}
      />
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyDz0iaw069jmolmRRRzRTRaCOCtdsSoaMg",
  libraries: ["drawing"],
})(MapPage);
