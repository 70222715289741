const mineData = [
    {
        id: "1",
        name: "SULIYARI COAL MINES",
        location:{
            lat: 23.93371751223374,
            lng: 82.33035954134644,
        },
        root:'SULIYARI',
        orthoPaths: ["9th_ortho", "10th_ortho"],
        DSMPaths:["output_9th_dsm", "output_10th_dsm"],
        DTMPaths:["output_9th_dtm", "output_10th_dtm"],
        geoPaths: ["output1.geojson", "output2.geojson"],
        modelPath: 'landsite1',
        elevationPath:['elevations/elevation1/elevation1.htm', 'elevations/elevation2/elevation2.htm'],
        mapBounds:{
            lat1:23.923104881414023,
            lat2:23.946892155311765,
            lng1:82.31238760208954,
            lng2:82.34542889305189
        },
        status:['success','Normal'],
        visits:['9th Visit', '10th Visit'],
        inspectionDate:"20th Sep 2023",
        inspectionArea:"5000 sq km",
        maxZoom:19,
        image:"SULIYARI_9th.png"
    },
    {
        id: "2",
        name: "GP-III_BAJARMUDA",
        location:{
            lat: 22.17416448102037,
            lng: 83.4871498117263,
        },
        root:"BAJARMUDA",
        orthoPaths: ["GP3fifth_tiles", "tiles"],
        DSMPaths:["output_gp3_dsm", "output_cgeck"],
        DTMPaths:["output_gp3_dtm", "output_cgeck"],
        geoPaths: ["output1.geojson", "output2.geojson"],
        modelPath: 'landsite2',
        elevationPath:null,
        mapBounds:{
            lat1:22.17416448102037,
            lat2:22.18858439575992,
            lng1:83.4871498117263,
            lng2:83.51723190023472
        },
        status:['warning','Minor'],
        visits:['5th Visit'],
        inspectionDate:"20th Sep 2023",
        inspectionArea:"5000 sq km",
        maxZoom:19,
        
    },
    {
        id: "3",
        name: "TALABIRA",
        location:{
            lat:  21.7420165602,
            lng: 83.9526246420,
        },
        root:"TALABIRA",
        orthoPaths: ["ortho"],
        DSMPaths:["dsm"],
        DTMPaths:["dtm"],
        geoPaths: ["contour1.geojson", "contour2.geojson"],
        modelPath: 'talbaria_site',
        elevationPath:["elevation1/talabira.htm"],
        mapBounds:{
            lat1: 21.7420165602,
            lat2:21.7703437069,
            lng1:83.9526246420,
            lng2:83.9886895997
        },
        status:['warning','Minor'],
        visits:['9th Visit'],
        inspectionDate:"20th Sep 2023",
        inspectionArea:"5000 sq km",
        maxZoom:21,
        image:"TALABIRA_9th.png"
    },
    {
        id: "4",
        name: "GONDBAHERA UJHENI_E",
        location:{
            lat: 24.170005,
            lng: 82.362587222,
        },
        root:"BAJARMUDA",
        orthoPaths: ["GP3fifth_tiles", "tiles"],
        DSMPaths:["output_gp3_dsm", "output_cgeck"],
        DTMPaths:["output_gp3_dtm", "output_cgeck"],
        geoPaths: ["output1.geojson", "output2.geojson"],
        modelPath: 'landsite2',
        elevationPath:null,
        mapBounds:{
            lat1:24.1492669444,
            lat2:24.1907430556,
            lng1:82.3313888889,
            lng2:82.3937855556
        },
        status:['warning','Minor'],
        visits:['5th Visit'],
        inspectionDate:"20th Sep 2023",
        inspectionArea:"5000 sq km",
        maxZoom:19
    },
    {
        id: "5",
        name: "ADANI_DAHEGAON",
        location:{
            lat: 21.273204306,
            lng: 78.963084306,
        },
        root:"BAJARMUDA",
        orthoPaths: ["GP3fifth_tiles", "tiles"],
        DSMPaths:["output_gp3_dsm", "output_cgeck"],
        DTMPaths:["output_gp3_dtm", "output_cgeck"],
        geoPaths: ["output1.geojson", "output2.geojson"],
        modelPath: 'landsite2',
        elevationPath:null,
        mapBounds:{
            lat1:21.2499030556,
            lat2:21.2965055556,
            lng1:78.9345013889,
            lng2:78.9916672222,
        },
        status:['warning','Minor'],
        visits:['5th Visit'],
        inspectionDate:"20th Sep 2023",
        inspectionArea:"5000 sq km",
        maxZoom:19
    },
    {
        id: "6",
        name: "MADHERI",
        location:{
            lat: 20.372803194,
            lng: 78.775341389,
        },
        root:"BAJARMUDA",
        orthoPaths: ["GP3fifth_tiles", "tiles"],
        DSMPaths:["output_gp3_dsm", "output_cgeck"],
        DTMPaths:["output_gp3_dtm", "output_cgeck"],
        geoPaths: ["output1.geojson", "output2.geojson"],
        modelPath: 'landsite2',
        elevationPath:null,
        mapBounds:{
            lat1:20.3452330556,
            lat2:20.4003733333,
            lng1:78.7252480556,
            lng2:78.8254347222,
        },
        status:['warning','Minor'],
        visits:['5th Visit'],
        inspectionDate:"20th Sep 2023",
        inspectionArea:"5000 sq km",
        maxZoom:19
    },
]

const mineDataById = mineData.map((el) => {
    let temp = {}
    temp[el.id] = el
    return temp
})

export { mineDataById, mineData }
