import React, { useContext } from 'react'
import './SiteCard.css'
import { useNavigate } from 'react-router-dom'
import RoomIcon from '@mui/icons-material/Room';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const SiteCard = ({ detail }) => {
    const navigate = useNavigate();
    const gotToPage = (detail) => {
        navigate("/maps/"+detail.id)
    }


    const getVolumeInPercent = () => {
        // const fraction = detail.basicInfo.vacantCapacity / detail.totalCapacity
        // const percent = ((detail.basicInfo.vacantCapacity * 100) % detail.totalCapacity === 0) ? (fraction * 100).toFixed() : (fraction * 100).toFixed(2)
        // const diff = 100 - percent
        // const classs = diff <= 1 ? 'empty' : diff > 1 && diff <= 25 ? 'low' : diff > 25 && diff <= 50 ? 'half' : diff > 50 && diff <= 75 ? "gt_50" : diff > 75 && diff <= 99 ? "gt_75" : 'full'
        // return [classs, percent]
        return ["high", 50]
    }

    const getOverallElementPercent = (elmVol) => {
        return (elmVol / detail.totalCapacity * 100).toFixed(2)

    }

    const getAvgCoord = (coord) => {
        const avgLat = (coord[0] + coord[2]) / 2
        const avgLong = (coord[1] + coord[3]) / 2
        return `${avgLat},${avgLong}`
    }


    return (
        <div
            className="block cursor-pointer text-xs max-w-sm pl-6 pr-6 pt-2 pb-2.5 bg-white border d-flex flex-column border-gray-200 rounded shadow mt-2 
             shadow-md sbucard_container"
            onClick={() => gotToPage(detail)}
        >
            <div className="d-flex align-items-center gap-2  px-3">
                <div style={{ borderRadius: "50%", backgroundColor: "rgb(23 88 159 / 60%)" }} className='h-12 w-12 flex align-items-start justify-center p-1'>
                    <img style={{ width: "2.5rem" }} src="/icons/coal.png" alt="warehouse" className='h-10 w-10' />
                </div>
                <div>
                <p></p>
                </div>
                <div className='pl-3' style={{ textAlign: "start" }}>
                    <h6 className=" text-l font-medium text-gray-900 light:text-white p-0 m-0 pt-2" style={{ fontSize: ".98rem" }}>{detail.name}</h6>
                    <p className='mb-1 p-0'></p>
                    <span className="text-sm text-gray-500 dark:text-gray-400" style={{ color: "rgba(156 ,163, 175 ,1)", fontSize:'0.98' }}><RoomIcon fontSize='small'/>{detail.location.lat.toFixed(5)+", "+detail.location.lng.toFixed(5)}</span>
                    <p className='m-0 p-0'></p>
                    <span title='Date of Inspection' className="text-sm text-gray-500 dark:text-gray-400" style={{ fontSize: ".98rem" }}><CalendarMonthIcon className='mb-1' fontSize='small' />  {detail.inspectionDate}</span>
                    {/* <span className="text-sm text-gray-500 dark:text-gray-400" style={{ fontSize: "1.0rem" }}>Date of Inspection: 20 Sept 2019</span> */}
                    <hr className="h-px mt-2 w-35 bg-gray-200 border-0 light:bg-gray-700"></hr>
                </div>
            </div>
        </div>
    )
}

export default SiteCard
