import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

const CalculateVolumeModal = ({
  volCalcType,
  setVolCalcType,
  customHeight,
  setCustomHeight,
  calculateVolume,
  volumeData,
  handleClickOnExportPDF
}) => {
  return (
    <>
      <div className="row">
        <h4>Calulate Volume</h4>
        <div className="col-12 mt-2">
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={volCalcType}
              label="Type"
              onChange={(e) => {
                setVolCalcType(e.target.value);
              }}
            >
              <MenuItem value={"min-height"}>Minimum Height</MenuItem>
              <MenuItem value={"custom"}>Custom Height</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-12 mt-3">
          {volCalcType == "custom" && (
            <div>
              <TextField
                value={customHeight}
                type="number"
                onChange={(e) => setCustomHeight(e.target.value)}
                id="outlined-basic"
                label="Height"
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{
                  style: { color: "rgba(0,0,0,.5)" },
                }}
              />
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-dark btn-sm my-2 mx-2"
            onClick={calculateVolume}
          >
            Calulate Volume
          </button>
          <button
            className="btn btn-dark btn-sm my-2 mx-2"
            onClick={handleClickOnExportPDF}
            disabled={!volumeData}
          >
            Export as PDF
          </button>
        </div>
        <div className="row text-center mt-2">
          <div className="col-4">
            <h6>Cut Volume</h6>
            {volumeData ? `${volumeData.cut_volume} m³` : "-"}
          </div>
          <div className="col-4">
            <h6>Fill Volume</h6>
            {volumeData ? `${volumeData.fill_volume} m³` : "-"}
          </div>
          <div className="col-4">
            <h6>Total Volume</h6>
            {volumeData ? `${volumeData.total_volume} m³` : "-"}
          </div>
        </div>
      </div>
    </>
  );
};

export default CalculateVolumeModal;
