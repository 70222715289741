import React, { useEffect, useState } from 'react'
import * as THREE from 'three'
import PotreeSidebar from '../components/PotreeSidebar'


const TestPotree = () => {
    const [viewer, setViewer] = useState(null)
    useEffect(()=>{
        console.log(window.Potree)
        window.viewer = new window.Potree.Viewer(document.getElementById('potree_render_area'))
        setViewer(window.viewer)
        window.viewer.setEDLEnabled(true);
		window.viewer.setFOV(60);
		window.viewer.setPointBudget(2_000_000);
		window.viewer.loadSettingsFromURL();
        // window.viewer.loadGUI(() => {
		// 	window.viewer.setLanguage('en');
		// 	$("#menu_appearance").next().show();
		// 	window.viewer.toggleSidebar();
		// });
        // window.viewer.loadGUI(() => {
		// 	window.viewer.setLanguage('en');
		// 	// document.getElementById("menu_appearance").next().show();
		// 	// window.viewer.toggleSidebar();
		// });
		async function run(){

			// this file contains coordinates, orientation and filenames of the images:
			// http://5.9.65.151/mschuetz/potree/resources/pointclouds/helimap/360/Drive2_selection/coordinates.txt
			window.Potree.loadPointCloud("/potree/pointclouds/landsite1/metadata.json", "lion", function(e){
                window.viewer.scene.addPointCloud(e.pointcloud);
                
                let material = e.pointcloud.material;
                material.size = 1;
                material.pointSizeType = window.Potree.PointSizeType.ADAPTIVE;
                
                window.viewer.fitToScreen();
            });

			// window.viewer.mapView.showSources(false);
		}
        run()

    },[])

  return (
    <div className="potree_container" style={{position: "absolute", width: "100%", height: "100%", left: "0px", top: "0px"}}>
		<div id="potree_render_area" style={{backgroundImage: "url('../../build/potree/resources/images/background.jpg')"}}>
		
		</div>
		<div id="potree_sidebar_container">
            <PotreeSidebar viewer={viewer} />
        </div>
	</div>
	
  )
}

export default TestPotree
