import React, { useEffect, useRef, useState } from "react";
import { mineData } from "../data";


import {
    Map,
    GoogleApiWrapper,
    Marker,
} from "google-maps-react";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import MapSwitch from "../components/MapSwitch";
import Spinner from "../components/Spinner";
import { IoArrowBackCircleOutline } from "react-icons/io5";
const CLOUD_STORAGE_PREFIX = "https://storage.googleapis.com/demo_adani/mine_sites/";


const CompareMaps = (props) => {
    const { id, idx } = useParams()
    const [detectChange, setDetectChange] = useState(false);
    const [currMapData, setCurrMapData] = useState({});
    const [map, setMap] = useState(null);
    const [contours, setContours] = useState(0);
    const mapRef = useRef(null);
    const mapRef1 = useRef(null);
    const [contourPath, setContourPath] = useState([])
    const [loading, setLoading] = useState(false)
    const [width, setWidth] = useState(window.innerWidth / 2);
    const [isDragging, setIsDragging] = useState(false);
    const [selectedLeft, setSelectedLeft] = useState(0)
    const [selectedRight, setSelectedRight] = useState(0)

    const startResizing = (mouseDownEvent) => {
        // Record the initial mouse position and width
        const startX = mouseDownEvent.clientX;
        const startWidth = width;

        const doDrag = (mouseMoveEvent) => {
            const newWidth = startWidth + mouseMoveEvent.clientX - startX;
            setWidth(newWidth);
        };

        const stopDrag = () => {
            window.removeEventListener('mousemove', doDrag);
            window.removeEventListener('mouseup', stopDrag);
            setIsDragging(false);
        };

        window.addEventListener('mousemove', doDrag);
        window.addEventListener('mouseup', stopDrag);
        setIsDragging(true);
    };

    const removeContour = () => {
        mapRef.current.map.data.forEach((feature) => {
            mapRef.current.map.data.remove(feature);
        });
    };




    useEffect(() => {
        const mapParams = mineData.find(el => el.id == id)
        setCurrMapData(mapParams)
        setContourPath(mapParams.geoPaths)
        props.setNavTitle(mapParams)
        console.log(mapParams)
        const map = mapRef.current.map;
        const map1 = mapRef1.current.map;
        window.currentMapTypeIndex = 0;
        window.currentMapTypeIndex1 = 0
        window.currentMapType = 'orthoPaths'
        window.currentMapType1 = 'orthoPaths'
        // map.setMapTypeId("satellite");
        setMap(map);
        const google = props.google;

        var mapBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(mapParams.mapBounds.lat1, mapParams.mapBounds.lng1),
            new google.maps.LatLng(mapParams.mapBounds.lat2, mapParams.mapBounds.lng2)
            // new google.maps.LatLng(23.92009928471577, 82.29913453439386),
            // new google.maps.LatLng(23.94689019999705, 82.36428002694757)
        );
        map.fitBounds(mapBounds);
        map1.fitBounds(mapBounds);
        // map.setZoom()
        // setMapCenter(mapParams.location)
        console.log("mapss", mapParams)
        map.setCenter(mapParams.location)
        map1.setCenter(mapParams.location)

        const overlayMapTiles = (mapSide = 0, index = 0, mapType = "orthoPaths") => {
            console.log(mapSide)
            if (mapSide === null || mapSide === 0) {
                const imageMapType = new google.maps.ImageMapType({
                    getTileUrl: function (coord, zoom) {

                        var proj = map.getProjection();
                        console.log(coord, zoom)
                        var tileSize = 256 / Math.pow(2, zoom);
                        var tileBounds = new google.maps.LatLngBounds(
                            proj.fromPointToLatLng(
                                new google.maps.Point(
                                    coord.x * tileSize,
                                    (coord.y + 1) * tileSize
                                )
                            ),
                            proj.fromPointToLatLng(
                                new google.maps.Point(
                                    (coord.x + 1) * tileSize,
                                    coord.y * tileSize
                                )
                            )
                        );
                        if (mapBounds.intersects(tileBounds)) {
                            return [
                                CLOUD_STORAGE_PREFIX + mapParams.root + "/maps/" + mapParams[mapType][index] + "/",
                                zoom,
                                "/",
                                coord.x,
                                "/",
                                Math.pow(2, zoom) - coord.y - 1,
                                ".png",
                            ].join("");
                        }
                        return "";
                    },
                    tileSize: new google.maps.Size(256, 256),
                });
                map.overlayMapTypes.push(imageMapType);

            }
            if (mapSide === null || mapSide === 1) {
                const imageMapType1 = new google.maps.ImageMapType({
                    getTileUrl: function (coord, zoom) {

                        var proj1 = map1.getProjection();
                        console.log(coord, zoom)
                        var tileSize = 256 / Math.pow(2, zoom);
                        var tileBounds = new google.maps.LatLngBounds(
                            proj1.fromPointToLatLng(
                                new google.maps.Point(
                                    coord.x * tileSize,
                                    (coord.y + 1) * tileSize
                                )
                            ),
                            proj1.fromPointToLatLng(
                                new google.maps.Point(
                                    (coord.x + 1) * tileSize,
                                    coord.y * tileSize
                                )
                            )
                        );
                        if (mapBounds.intersects(tileBounds)) {
                            return [
                                CLOUD_STORAGE_PREFIX + mapParams.root + "/maps/" + mapParams[mapType][index] + "/",
                                zoom,
                                "/",
                                coord.x,
                                "/",
                                Math.pow(2, zoom) - coord.y - 1,
                                ".png",
                            ].join("");
                        }
                        return "";
                    },
                    tileSize: new google.maps.Size(256, 256),
                });
                map1.overlayMapTypes.push(imageMapType1);
            }
        }

        const removeOverlayTiles = (mapSide) => {
            mapSide === 0 ?
                map.overlayMapTypes.forEach((el, i) => {
                    map.overlayMapTypes.removeAt(i)
                }) :
                map1.overlayMapTypes.forEach((el, i) => {
                    map1.overlayMapTypes.removeAt(i)
                })
        }

        overlayMapTiles(null, idx);
        // window.remove
        window.chnageMapByDate = (mapSide, index, mapType = 'orthoPaths') => {
            removeOverlayTiles(mapSide);
            if (mapSide === 0) {
                window.currentMapTypeIndex = index;
                overlayMapTiles(mapSide, window.currentMapTypeIndex, mapType);
            } else {
                window.currentMapTypeIndex1 = index;
                overlayMapTiles(mapSide, window.currentMapTypeIndex1, mapType);
            }
            setDetectChange(prev => !prev)
        }

        window.changeMap = (index, mapType) => {
            removeOverlayTiles(0);
            removeContour();
            overlayMapTiles(0, window.currentMapTypeIndex, mapType)
            window.currentMapType = mapType
        }
        window.changeMap1 = (index, mapType) => {
            removeOverlayTiles(1);
            removeContour();
            overlayMapTiles(1, window.currentMapTypeIndex1, mapType)
            window.currentMapType1 = mapType
        }

        window.syncMaps = (...maps) => {
            let center, zoom;

            function update(changedMap) {
                maps.forEach((m) => {
                    if (m === changedMap) {
                        return;
                    }

                    m.setCenter(center);
                    m.setZoom(zoom);
                });
            }

            maps.forEach((m) => {
                m.addListener("bounds_changed", () => {
                    const changedCenter = m.getCenter();
                    const changedZoom = m.getZoom();

                    if (changedCenter !== center || changedZoom !== zoom) {
                        center = changedCenter;
                        zoom = changedZoom;
                        update(m);
                    }
                });
            });
        }
        window.syncMaps(map, map1)

    }, [props.google, contours]);
    useEffect(() => {
        setSelectedRight(0);
    }, [window.currentMapTypeIndex])
    useEffect(() => {
        setSelectedLeft(0);
    }, [window.currentMapTypeIndex1])
    return (
        <>
            <Navbar setNavTitle={props.setNavTitle} navTitle={props.navTitle} />
            {loading &&
                <div
                    style={{
                        background: 'rgba(255, 255, 255, 0.5)',
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "1",
                        position: "absolute"
                    }}
                ><Spinner /></div>
            }
            <div className="mapcontainer" style={{ height: "100vh", width: "100%", position: "relative", pointerEvents:isDragging?"none":"all" }}>
                <div className="map1" style={{
                    height: "100vh", width: "100%",
                    position: "absolute",

                }}>
                    
                        <Map
                            google={props.google}
                            zoom={15}
                            style={{
                                width: "100%",
                                float: "right"
                            }}
                            streetViewControl={false}

                            initialCenter={{
                                lat: 23.93371751223374,
                                lng: 82.33035954134644,
                            }}
                            mapTypeControl={true}
                            disableDefaultUI={true}
                            ref={mapRef}
                            maxZoom={19}
                            minZoom={15}
                            mapTypeControlOptions={{
                                position: props.google.maps.ControlPosition.RIGHT_TOP,
                            }}
                        >

                        </Map>
                    

                </div>
                <div className="map2" style={{ width: width + "px", height: "100vh", position: "absolute", zIndex: "999", overflow: "hidden" }}>
                    <div style={{ position: "relative", height: "100%", width: `${width}px` }}>
                        
                            <Map
                                google={props.google}
                                zoom={15}
                                style={{
                                    width: "100vw",
                                    float: "left",
                                }}
                                streetViewControl={false}

                                initialCenter={{
                                    lat: 23.93371751223374,
                                    lng: 82.33035954134644,
                                }}
                                mapTypeControl={true}
                                disableDefaultUI={true}
                                ref={mapRef1}
                                maxZoom={19}
                                minZoom={15}
                                mapTypeControlOptions={{
                                    position: props.google.maps.ControlPosition.LEFT_TOP,
                                }}
                            >

                            </Map>
                        
                        <button style={{
                            position:"fixed",
                            zIndex:2,
                            left:"12%",
                            top:"7.5%"

                        }} className="btn btn-dark btn-sm" title="Back" onClick={() => window.history.go(-1)} >
                            <IoArrowBackCircleOutline size={28}/>
                        </button>
                        <div
                            style={{
                                width: "10px",
                                height: "100%",
                                backgroundColor: "#212529",
                                backgroundRepeat: "no-repeat",
                                position: "absolute",
                                right: 0,
                                top: 0,
                                cursor: "col-resize",
                                backgroundImage: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==)",
                                backgroundPosition: "50%"
                            }}
                            onMouseDown={startResizing}
                        ></div>
                    </div>
                </div>
            </div>



            <nav
                className="navbar navbar-expand-lg navbar-light bg-dark-o rounded"
                style={{
                    position: "absolute",
                    zIndex: 9999,
                    top: "8%",
                    right: "55%",

                    padding: "5px",
                }}
            >
                <div className="d-flex gap-4">

                    <div className="btn-group ">
                        <button style={{ pointerEvents: "none" }} className="btn btn-dark btn-sm" >Comparison Layers:</button>
                        <button className="btn btn-dark btn-sm" style={{
                            backgroundColor: selectedLeft === 0 ? "grey" : ""
                        }} onClick={() => { setSelectedLeft(0); window.changeMap1(1, 'orthoPaths') }} >Ortho</button>
                        <button className="btn btn-dark btn-sm" style={{
                            backgroundColor: selectedLeft === 1 ? "grey" : ""
                        }} onClick={() => { setSelectedLeft(1); window.changeMap1(1, 'DTMPaths') }} >DTM</button>
                        <button className="btn btn-dark btn-sm" style={{
                            backgroundColor: selectedLeft === 2 ? "grey" : ""
                        }} onClick={() => { setSelectedLeft(2); window.changeMap1(1, 'DSMPaths') }} >DSM</button>
                    </div>
                </div>
            </nav>
            <nav
                className="navbar navbar-expand-lg navbar-light bg-dark-o rounded"
                style={{
                    position: "absolute",
                    zIndex: 9999,
                    top: "8%",
                    right: "28%",
                    padding: "5px",
                }}
            >
                <div className="d-flex gap-4">

                    <div className="btn-group ">
                        <button style={{ pointerEvents: "none" }} className="btn btn-dark btn-sm" >Comparison Layers:</button>
                        <button style={{
                            backgroundColor: selectedRight === 0 ? "grey" : ""
                        }} className="btn btn-dark btn-sm" onClick={() => { setSelectedRight(0); window.changeMap(0, 'orthoPaths') }} >Ortho</button>
                        <button style={{
                            backgroundColor: selectedRight === 1 ? "grey" : ""
                        }} className="btn btn-dark btn-sm" onClick={() => { setSelectedRight(1); window.changeMap(0, 'DTMPaths') }} >DTM</button>
                        <button style={{
                            backgroundColor: selectedRight === 2 ? "grey" : ""
                        }} className="btn btn-dark btn-sm" onClick={() => { setSelectedRight(2); window.changeMap(0, 'DSMPaths') }} >DSM</button>
                    </div>
                </div>
            </nav>
            
            

            <MapSwitch
                getMap1={window.chnageMapByDate}
                currentMapType={window.currentMapType}
                currentMapTypeIndex={window.currentMapTypeIndex}
                clearContour={removeContour}
                currMapData={currMapData}
                style={{ right: "19%", top:"8.5%" }}
                mapSide={0}
            />
            <MapSwitch
                getMap1={window.chnageMapByDate}
                currentMapType={window.currentMapType1}
                currentMapTypeIndex={window.currentMapTypeIndex1}
                clearContour={removeContour}
                currMapData={currMapData}
                style={{ right: "73%", top:"8.5%" }}
                mapSide={1}
            />

        </>
    );
};

export default GoogleApiWrapper({
    apiKey: "AIzaSyDz0iaw069jmolmRRRzRTRaCOCtdsSoaMg",
})(CompareMaps);
