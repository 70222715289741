import React from 'react'
import { useNavigate } from 'react-router-dom'

const SwitchDim = ({currMapData}) => {
  const navigate = useNavigate();
  const toggleDim = (path)=>{
    console.log(path)
    navigate(path)
  }
  return (
    <nav
            className="navbar navbar-expand-lg navbar-light bg-dark-o rounded"
            style={{
                position: "absolute",
                zIndex: 2,
                top: "20%",
                right: "2%",
                padding:"5px",
            }}
        >
          <div className="d-flex gap-4">

            <div className="btn-group ">
              <button className="btn btn-dark btn-sm" onClick={()=> toggleDim('/maps/'+currMapData.id)} >2D</button>
              <button className="btn btn-dark btn-sm" onClick={()=> toggleDim(`/maps3d?path=${currMapData.modelPath}&id=${currMapData.id}`)} >3D</button>
            </div>
          </div>
        </nav>
  )
}

export default SwitchDim
