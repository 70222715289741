import React, { useState } from "react";
import "./index.css";
import {
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoginIcon from "@mui/icons-material/Login";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const [loginCreds, setLoginCreds] = useState({username:'', password:''})

  const loginAdmin = ()=>{
    if(!(loginCreds.username==='admin' && loginCreds.password==='admin')) {
      window.alert("Wrong email or password!")
      return
    };
    localStorage.setItem("login","admin")
    navigate('/mapview');
  }
  const label = {
    inputProps: { "aria-label": "Checkbox demo", color: "rgb(102, 127, 193)" },
  };

  return (
    <div className="login_container">
      <div className="video_container">
        <video autoPlay muted loop className="login_video">
          <source src="./mining.mp4" type="video/mp4"></source>
        </video>
      </div>
      <div className="video-overlay"></div>

      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div className="login_main_container">
            <div className="login_image_container">
              <img width="500px" style={{pointerEvents:"none"}} src="https://upload.wikimedia.org/wikipedia/commons/e/e4/Adani_2012_logo.png" alt="" />
            </div>
            <div className="login_vertical_line"></div>
          <div className="login_form_container bg-transparent text-light p-4 rounded">
            <div className="d-flex justify-content-center flex-column mb-4">
              <a href="#">
                <img className="phiscan-logo mb-2" style={{filter:"invert()"}}  src="./phicode.png" alt="logo" />
              </a>
              <span className="h3">PhiScan</span>
            </div>
            <form className="" action="#">
              <Stack direction="column" spacing={2} className="mb-4">
                <TextField
                  id="outlined-basic"
                  label="Username"
                  variant="outlined"
                  value={loginCreds.username}
                  onChange={(e)=> setLoginCreds(prev=>({...prev, username:e.target.value}))}
                  // sx={{borderColor:"white"}}
                  onKeyDown={(e)=> e.key==='Enter'&&loginAdmin()}
                  inputProps={{
                    style:{color:'white', backgroundColor:"#6f6c6c85", borderRadius: '5px'}
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  type="password"
                  value={loginCreds.password}
                  onChange={(e)=> setLoginCreds(prev=>({...prev, password:e.target.value}))}
                  // sx={{borderColor:"white"}}
                  onKeyDown={(e)=> e.key==='Enter'&&loginAdmin()}
                  inputProps={{
                    style:{color:'white', backgroundColor:"#6f6c6c85", borderRadius: '5px'}
                  }}
                />
                {/* <FormControl variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    
                  />
                </FormControl> */}
                <Button
                  variant="contained"
                  endIcon={<LoginIcon />}
                  color="primary"
                  onClick={()=> loginAdmin()}
                  //   style={{ backgroundColor: "rgb(102, 127, 193)" }}
                >
                  Login
                </Button>
              </Stack>
              <div className="mb-4 d-flex align-items-center justify-content-center">
                <Checkbox {...label} />
                <label htmlFor="remember" className="">
                  Remember me for 30 days
                </label>
              </div>
              <p className="">
                Don’t have an account? &nbsp;
                <br />
                <a href="#" className="" style={{ color: "rgb(255 255 255)" }}>
                  Create account
                </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;