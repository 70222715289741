import React, { useEffect } from 'react'

const PotreeSidebar = ({viewer}) => {
    useEffect(()=>{
        viewer?.loadGUI(() => {
            console.log("viewerrr")
			viewer.setLanguage('en');
            console.log("viewe", viewer)
			// document.getElementById("menu_appearance").next().show();
			// window.viewer.toggleSidebar();
		});
    },[])
  return (
    <div id="sidebar_root" className="">
  <div id="sidebar_header">
    <span id="potree_branding" className="potree_sidebar_brand">
      <a href="http://potree.org" target="_blank">
        potree.org
      </a>
      <span style={{ margin: "0px 3px", color: "#9AA1A4" }}> - </span>
      <a href="https://github.com/potree/potree" target="_blank">
        github
      </a>
      <span style={{ margin: "0px 3px", color: "#9AA1A4" }}> - </span>
      <a href="https://twitter.com/m_schuetz" target="_blank">
        twitter
      </a>
      <span style={{ flexGrow: 1 }} />
      <span
        id="potree_version_number"
        style={{ color: "#9AA1A4", fontSize: "80%", fontWeight: 100 }}
      />
    </span>
    <div id="potree_languages" style={{ fontFamily: "arial" }} />
  </div>
  <div>
    <div id="potree_menu" className="accordion">
      {/* APPEARANCE */}
      <h3 id="menu_appearance">
        <span data-i18n="tb.rendering_opt" />
      </h3>
      <div>
        <ul className="pv-menu-list">
          <li>
            <span data-i18n="appearance.nb_max_pts" />:{" "}
            <span id="lblPointBudget" /> <div id="sldPointBudget" />
          </li>
          <li>
            <span data-i18n="appearance.field_view" />: <span id="lblFOV" />
            <div id="sldFOV" />
          </li>
          <div className="divider">
            <span>Eye-Dome-Lighting</span>
          </div>
          <li>
            <label>
              <input type="checkbox" id="chkEDLEnabled" />
              <span data-i18n="appearance.edl_enable" />
            </label>
          </li>
          <li>
            <span data-i18n="appearance.edl_radius" />:{" "}
            <span id="lblEDLRadius" />
            <div id="sldEDLRadius" />
          </li>
          <li>
            <span data-i18n="appearance.edl_strength" />:{" "}
            <span id="lblEDLStrength" />
            <div id="sldEDLStrength" />
          </li>
          <li>
            <span data-i18n="appearance.edl_opacity" />:{" "}
            <span id="lblEDLOpacity" />
            <div id="sldEDLOpacity" />
          </li>
          <div className="divider">
            <span>Background</span>
          </div>
          <li>
            <selectgroup id="background_options">
              <option id="background_options_skybox" value="skybox">
                Skybox
              </option>
              <option id="background_options_gradient" value="gradient">
                Gradient
              </option>
              <option id="background_options_black" value="black">
                Black
              </option>
              <option id="background_options_white" value="white">
                White
              </option>
              <option id="background_options_none" value="null">
                None
              </option>
            </selectgroup>
          </li>
          <div className="divider">
            <span>Other</span>
          </div>
          <li>
            <selectgroup id="splat_quality_options">
              <option id="splat_quality_options_standard" value="standard">
                Standard
              </option>
              <option id="splat_quality_options_hq" value="hq">
                High Quality
              </option>
            </selectgroup>
          </li>
          <li>
            <span data-i18n="appearance.min_node_size" />:{" "}
            <span id="lblMinNodeSize" />
            <div id="sldMinNodeSize" />
          </li>
          <li>
            <label>
              <input id="show_bounding_box" type="checkbox" />
              <span data-i18n="appearance.box" />
            </label>
          </li>
          <li>
            <label>
              <input id="set_freeze" type="checkbox" />
              <span data-i18n="appearance.freeze" />
            </label>
          </li>
        </ul>
      </div>
      {/* TOOLS */}
      <h3 id="menu_tools">
        <span data-i18n="tb.tools_opt" />
      </h3>
      <div className="pv-menu-list">
        <div className="divider">
          <span>Measurement</span>
        </div>
        <li id="tools" />
        <li>
          <selectgroup id="measurement_options_show">
            <option id="measurement_options_show_yes" value="SHOW">
              Show
            </option>
            <option id="measurement_options_show_no" value="HIDE">
              Hide
            </option>
          </selectgroup>
        </li>
        <div className="divider">
          <span>Clipping</span>
        </div>
        <li id="clipping_tools" />
        <li>
          <selectgroup id="cliptask_options">
            <option id="cliptask_options_none" value="NONE">
              None
            </option>
            <option id="cliptask_options_highlight" value="HIGHLIGHT">
              Highlight
            </option>
            <option id="cliptask_options_show_inside" value="SHOW_INSIDE">
              Inside
            </option>
            <option id="cliptask_options_show_outside" value="SHOW_OUTSIDE">
              Outside
            </option>
          </selectgroup>
        </li>
        <li>
          <selectgroup id="clipmethod_options">
            <option id="clipmethod_options_any" value="INSIDE_ANY">
              Inside Any
            </option>
            <option id="clipmethod_options_all" value="INSIDE_ALL">
              Inside All
            </option>
          </selectgroup>
        </li>
        <div className="divider">
          <span>Navigation</span>
        </div>
        <li id="navigation" />
        <li>
          <span data-i18n="appearance.move_speed" />: <span id="lblMoveSpeed" />
          <div id="sldMoveSpeed" />
        </li>
      </div>
      {/* SCENE */}
      <h3 id="menu_scene">
        <span data-i18n="tb.scene_opt" />
      </h3>
      <div className="pv-menu-list">
        <div id="scene_export" />
        <div className="divider">
          <span>Objects</span>
        </div>
        <div id="scene_objects" />
        <div className="divider">
          <span>Properties</span>
        </div>
        <div id="scene_object_properties" />
      </div>
      {/* Classification */}
      <h3 id="menu_filters">
        <span data-i18n="tb.filters_opt" />
      </h3>
      <div>
        <div className="divider">
          <span>Classification</span>
        </div>
        <ul id="classificationList" className="pv-menu-list" />
        <div className="divider">
          <span>Returns</span>
        </div>
        <div id="return_filter_panel">
          <ul className="pv-menu-list">
            <li>
              <span data-i18n="filters.return_number" />:{" "}
              <span id="lblReturnNumber" /> <div id="sldReturnNumber" />
            </li>
            <li>
              <span data-i18n="filters.number_of_returns" />:{" "}
              <span id="lblNumberOfReturns" /> <div id="sldNumberOfReturns" />
            </li>
          </ul>
        </div>
        <div className="divider">
          <span>Point Source ID</span>
        </div>
        <div>
          <ul className="pv-menu-list" id="pointsourceid_filter_panel"></ul>
        </div>
        <div className="divider">
          <span>GPS Time</span>
        </div>
        <div id="gpstime_filter_panel">
          <ul className="pv-menu-list">
            <div id="gpstime_multilevel_range_container">
              <li>
                <span style={{ display: "flex" }}>
                  <span>Time: </span>
                  <input
                    id="txtGpsTime"
                    type="text"
                    style={{ margin: "auto 10px" }}
                  />
                  <input
                    id="btnFindGpsTime"
                    type="button"
                    defaultValue="find"
                  />
                </span>
              </li>
            </div>
          </ul>
        </div>
      </div>
      {/* ABOUT */}
      <h3 id="menu_about">
        <span data-i18n="tb.about_opt" />
      </h3>
      <div>
        <ul className="pv-menu-list">
          <li>
            <a href="http://potree.org" target="_blank">
              Potree
            </a>{" "}
            is a viewer for large point cloud / LIDAR data sets, developed at
            the Vienna University of Technology.
            <a href="https://github.com/potree/potree" target="_blank">
              (github)
            </a>
          </li>
          <li>
            <b>Author: </b>
            <a href="mailto:mschuetz@potree.org">Markus Schütz</a>
          </li>
          <li>
            <b>License: </b>
            <a
              target="_blank"
              href="https://github.com/potree/potree/blob/develop/LICENSE/"
            >
              FreeBSD (2-clause BSD)
            </a>
          </li>
          <li>
            <b>Dependency Licenses:</b>{" "}
            <a
              target="_blank"
              href="https://github.com/potree/potree/tree/develop/libs/"
            >
              See github
            </a>
          </li>
          <li>
            <b>Funding:</b>
            Potree is funded by a combination of research projects, companies,
            institutions and individuals. If you're making good use of Potree,
            please consider funding its future development{" "}
            <a href="https://github.com/sponsors/potree">
              via Github Sponsors
            </a>{" "}
            or by directly inquiring{" "}
            <a href="mailto:mschuetz@potree.org">via e-mail</a>.
            <br />
            <br />
            Research projects who's funding contributes to Potree:
            <table>
              <tbody>
                <tr>
                  <th>Project Name</th>
                  <th>Funding Agency</th>
                </tr>
                <tr>
                  <td>
                    <a href="https://projekte.ffg.at/projekt/3851914">
                      LargeClouds2BIM
                    </a>
                  </td>
                  <td>
                    <a href="https://www.ffg.at/">FFG</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="https://harvest4d.org/">Harvest4D</a>
                  </td>
                  <td>
                    <a href="https://ec.europa.eu/transport/themes/research/fp7_en">
                      EU 7th Framework Program 323567
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="https://gcd.tuwien.ac.at/">GCD Doctoral College</a>
                  </td>
                  <td>
                    <a href="https://www.tuwien.at/en/">TU Wien</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="https://www.cg.tuwien.ac.at/research/projects/Superhumans/">
                      Superhumans
                    </a>
                  </td>
                  <td>
                    <a href="https://www.fwf.ac.at/">FWF</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            Thanks to all the companies and institutions funding Potree:
            <table style={{ width: "100%", textAlign: "center" }}>
              <tbody>
                <tr>
                  <th style={{ paddingTop: "0.7em" }}>• Diamond •</th>
                </tr>
                <tr>
                  <td>
                    <a href="http://www.ne.ch/autorites/DDTE/SGRF/SITN/Pages/accueil.aspx">
                      SITN
                    </a>{" "}
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="http://synth.earth/">Synth</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="http://www.geocue.com">GeoCue Group</a>{" "}
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="http://rapidlasso.com">Rapidlasso</a>{" "}
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingTop: "0.7em" }}>• Gold •</th>
                </tr>
                <tr>
                  <td>
                    <a href="https://www.bart.gov">BART</a>
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingTop: "0.7em" }}>• Silver •</th>
                </tr>
                <tr>
                  <td>
                    <a href="https://biology.anu.edu.au/research/facilities/australian-plant-phenomics-facility-anu">
                      APPF ANU
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="https://www.limit-addict.fr/">LimitAddict</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="http://georepublic.info">Georepublic</a>
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingTop: "0.7em" }}>• Bronze •</th>
                </tr>
                <tr>
                  <td>
                    <a href="https://www.eventart.at/">EventArt</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="https://www.geodelta.com/">Geodelta</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="https://www.e-cassini.fr/">E-Cassini</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="https://www.sogelink.fr/">Sogelink</a>
                  </td>
                </tr>
                <tr>
                  <td>Data-viewer</td>
                </tr>
                <tr>
                  <td>
                    <a href="http://www.helimap.com/">Helimap</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="http://www.vevey.ch/">Vevey</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="https://www.yverdon-les-bains.ch/">
                      yverdon-les-bains
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="http://archpro.lbg.ac.at">LBI ArchPro</a>{" "}
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="http://www.kts.co.jp">KTS</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="http://veesus.com">Veesus</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="http://www.sigeom.ch">Sigeom</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</div>

  )
}

export default PotreeSidebar
