import React, { useEffect, useState } from "react";
// import { Map } from '@googlemaps/react-wrapper'
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import iconn from "../Markers/coal.png";
import { mineData } from "../data";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import SitesSideBar from "../components/SitesSideBar";
import { minesList } from "../minesList";
import Spinner from "../components/Spinner";

const mapStyles = {
    width: "100%",
    height: "100%",
    position: "relative",
};

const getAvgCoord = (coord) => {
    const avgLat = (coord[0] + coord[2]) / 2
    const avgLong = (coord[1] + coord[3]) / 2
    return [avgLat, avgLong]
}




const MapView = (props) => {
    const navigate = useNavigate()
    const [isMapLoaded, setIsMapLoaded] = useState(false)
    useEffect(()=>{
        if(localStorage.getItem("login")!=='admin'){
            navigate("/")
        }
        props.setNavTitle('')
        setTimeout(() => {
            setIsMapLoaded(true)
        }, 3000);
    },[])
    return (
        <>{
            !isMapLoaded?<div style={{
                backgroundColor:'white',
                width:"100vw",
                height:"100vh",
                display:"flex",
                justifyContent:"center",
                alignItems:"center"
            }}>
                <Spinner />
            </div>
            :
            <> 
            <Navbar setNavTitle={props.setNavTitle} navTitle={props.navTitle} />
            <SitesSideBar />
            <Map
                google={props.google}
                zoom={5}
                style={mapStyles}
                streetViewControl={false}
                initialCenter={{
                    lat: 20.5937,
                    lng: 78.9629,
                }}
                disableDefaultUI={true}
                minZoom={6}
               
            >
                {mineData?.map((marker) => {
                    return <Marker
                        key={
                            marker.location.lat.toString() + marker.location.lng
                        }
                        position={marker.location}
                        icon={{
                            url: iconn,
                            scaledSize: new props.google.maps.Size(35, 35),
                        }}
                        style={{ width: "16px" }}
                        onClick={() => navigate("/maps/" + marker.id)}
                        title={marker.name}
                    />
                })}
            </Map>
            </>
        }
        </>

    );
};

export default GoogleApiWrapper({
    apiKey: "AIzaSyDz0iaw069jmolmRRRzRTRaCOCtdsSoaMg",
})(MapView);
