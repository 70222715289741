import React from "react";
import { BiArea } from "react-icons/bi";
import { GiPathDistance } from "react-icons/gi";
import { GrPowerReset } from "react-icons/gr";
import { MdOutlineCompare } from "react-icons/md";
import { VscGraphLine } from "react-icons/vsc";
import { MdDraw } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { mineData } from "../../data";

const Toolbar = ({
  startLineCal,
  startAreaCal,
  resetPolys,
  mapId,
  currentMapTypeIndex = "0",
  toggleDraw,
  exportGeoJson,
  saveToDb,
  removeDrawnPoly,
}) => {
  const navigate = useNavigate();
  const currElvPath = mineData.find((el) => el.id == mapId).elevationPath;
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-dark-o rounded"
      style={{
        position: "absolute",
        zIndex: 2,
        top: "15%",
        right: "2%",
        padding: "5px",
      }}
    >
      <div className="d-flex gap-4">
        <div className="btn-group ">
          <button
            className="btn btn-dark btn-sm"
            title="Start Area"
            onClick={() => resetPolys() && startAreaCal()}
          >
            <BiArea size={20} />
          </button>
          <button
            className="btn btn-dark btn-sm"
            title="Start Distance"
            onClick={() => resetPolys() && startLineCal()}
          >
            <GiPathDistance size={20} />
          </button>
          <button
            className="btn btn-dark btn-sm"
            title="Reset"
            onClick={resetPolys}
          >
            <GrPowerReset size={20} />
          </button>
          <button
            className="btn btn-dark btn-sm"
            title="Elevations"
            onClick={() =>
              currElvPath &&
              navigate("/elevation?id=" + mapId + "&idx=" + currentMapTypeIndex)
            }
          >
            <VscGraphLine size={20} />
          </button>
          <button
            className="btn btn-dark btn-sm"
            title="Compare Visits"
            onClick={() => navigate("/cmaps/" + mapId)}
          >
            <MdOutlineCompare size={20} />
          </button>
          <button
            className="btn btn-dark btn-sm"
            title="Compare Visits"
            onClick={() => toggleDraw()}
          >
            <MdDraw size={20} />
          </button>
        </div>
        <div className="btn-group">
          <button className="btn btn-dark btn-sm" onClick={saveToDb}>
            Save Drawings
          </button>
          {/* <button className="btn btn-dark btn-sm" onClick={exportGeoJson}>Export Drawings</button> */}
          <button className="btn btn-dark btn-sm" onClick={removeDrawnPoly}>
            Remove Drawings
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Toolbar;
